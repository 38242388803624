import React from "react";
import theme from "theme";
import { Theme, Link, Strong, Text, Box, Section, Image, Hr, Em, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Section
			min-height="100vh"
			lg-padding="120px 0 88px 0"
			md-padding="140px 0 96px 0"
			sm-padding="60px 0 96px 0"
			quarkly-title="Hero-23"
			padding="88px 0 88px 0"
			background="radial-gradient(50% 50% at 52.09% 50%,rgba(4, 8, 12, 0.5) 67.71%,rgba(4, 8, 12, 0.6) 100%),url(https://images.unsplash.com/photo-1507358522600-9f71e620c44e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center center/cover no-repeat"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				lg-flex-direction="column"
				display="flex"
				lg-width="100%"
				align-items="flex-start"
				lg-align-items="center"
				lg-justify-content="center"
				width="100%"
				flex-direction="row"
			>
				<Text
					color="#FFFFFF"
					font="normal 300 20px/1.5 --fontFamily-googleOutfit"
					width="100%"
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
					sm-margin="0px 0px 12px 0px"
					letter-spacing="2px"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						H I R E S T
					</Strong>
				</Text>
			</Box>
			<Box
				margin="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				lg-justify-content="center"
				display="flex"
				lg-align-items="center"
				align-items="flex-end"
				lg-width="100%"
				padding="0px 0px 0px 0px"
				justify-content="space-between"
				lg-flex-direction="column"
				width="100%"
				flex-direction="row"
			>
				<Text
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					width="25%"
					md-width="100%"
					margin="0px 0px 0px 0px"
					lg-width="100%"
					color="--light"
					font="normal 900 42px/1.2 --fontFamily-googleNotoSansKr"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-text-align="center"
					lg-margin="0px 0px 20px 0px"
					lg-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					My journey through Thailand
				</Text>
				<Text
					color="#FFFFFF"
					font="--base"
					width="25%"
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
				>
					Our classes program provides a gateway into the world of dance connecting young people and communities to artistic practice and engaging them.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="url() 0 0 no-repeat" quarkly-title="About-11">
			<Override
				slot="SectionContent"
				flex-direction="row"
				md-flex-wrap="wrap"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				width="20%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				padding="0px 0px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 30px 0px"
				lg-width="30%"
			>
				<Image
					src="https://i.ibb.co/cY93Q3c/logo-removebg-preview.png"
					md-margin="0px 0px 20px 0px"
					width="200px"
					height="200px"
					object-fit="cover"
					border-radius="50%"
					lg-width="200px"
				/>
			</Box>
			<Box
				width="80%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				align-items="flex-start"
				padding="0px 128px 0px 128px"
				lg-padding="0px 64px 0px 64px"
				lg-width="70%"
				md-padding="0px 0px 0px 0px"
			>
				<Box display="flex" align-items="center">
					<Hr
						margin="0px 20px 0px 0px"
						width="80px"
						border-color="--color-indigo"
						align-items="center"
						display="flex"
						justify-content="center"
						border-width="0 0 2px 0"
						height="2px"
						padding="0px 0px 2px 0px"
					/>
					<Text
						color="--indigo"
						font="--lead"
						margin="0px 0px 0px 0px"
						text-transform="uppercase"
						letter-spacing="1px"
					>
						Our VISION
					</Text>
				</Box>
				<Text margin="32px 0px 18px 0px" font="--headline2" md-margin="24px 0px 30px 0px" sm-font="--headline3">
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						color="--indigo"
					>
						"
					</Em>
					<Span color="--dark">
						Working from this
place is a pleasure not
your duty!
					</Span>
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						color="--indigo"
					>
						"
					</Em>
				</Text>
				<Text margin="0px 0px 38px 0px" color="--darkL2" font="--lead">
					<Em>
						Mark Wheller, one of Coworking founders
					</Em>
				</Text>
				<Link
					href="#"
					color="--lightD1"
					text-decoration-line="initial"
					padding="7px 24px 8px 24px"
					background="--color-dark"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					letter-spacing="1px"
					border-radius="4px"
				>
					Read More
				</Link>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6637488ffdcae00021e1f43e"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});